html {
  background-color: black;
  color: white;
}
.app {
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cancel-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  fill: white;
  transition: 0.4s;
}

.cancel-btn:hover {
  scale: 1.2;
}

.item-collection {
  border: 0.2px solid white;
  padding-bottom: 15px;
  width: 500px;
  height: 250px;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;

  position: relative;
}

.item {
  box-sizing: border-box;
  background-color: white;
  width: 8px;
}

.current {
  background-color: transparent;
  border: 0.5px solid white;
}

.sorted {
  background-color: transparent;
  border: 0.5px solid white;
}

.controls {
  width: 500px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
button {
  background-color: black;
  color: white;
  padding: 10px;
  padding-inline: 20px;

  border: 0.5px solid white;
}

button:hover {
  background-color: white;
  color: black;
}

button:disabled {
  background-color: white;
  color: black;
}
